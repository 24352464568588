const Spinner = (element, config) => {
    const spinner = element.querySelector(config.spinnerSelector);

    if (!spinner) {
        console.warn('no spinner html element found');
    }

    return {
        show: spinner ? () => spinner.classList.remove('d-none') : () => undefined,
        hide: spinner ? () => spinner.classList.add('d-none') : () => undefined
    };
};

const Error = (element, config) => {
    const errorMessageTarget = element.querySelector(config.errorSelector);

    if (!errorMessageTarget) {
        console.warn('no error message html element found');
    }

    return {
        show: (message) => {
            if (errorMessageTarget) {
                errorMessageTarget.innerText = message;
                errorMessageTarget.classList.remove('d-none');
            }
        },
        hide: () => {
            if (errorMessageTarget) {
                errorMessageTarget.innerText = '';
                errorMessageTarget.classList.add('d-none');
            }
        }
    };
};

const Success = (element, config) => {
    const successMessageTarget = element.querySelector(config.successSelector);

    if (!successMessageTarget) {
        console.warn('no success message html element found');
    }

    return {
        show: (message) => {
            if (successMessageTarget) {
                if (message instanceof HTMLElement) {
                    successMessageTarget.innerHTML = message.innerHTML;
                } else {
                    successMessageTarget.innerText = message;
                }

                successMessageTarget.classList.remove('d-none');
            }
        },
        hide: () => {
            if (successMessageTarget) {
                successMessageTarget.innerHTML = '';
                successMessageTarget.innerText = '';
                successMessageTarget.classList.add('d-none');
            }
        }
    };
};

const Form = (element, config) => {
    const form = element.querySelector('form');

    return {
        form,
        hide: () => form.classList.add('d-none'),
        show: () => form.classList.remove('d-none'),
    };
};

const clearPreviousErrors = (form) => {
    const invalidFeedbacks = form.querySelectorAll('[class~="invalid-feedback"]');
    Array.from(invalidFeedbacks).forEach((invalidFeedback) => {
        invalidFeedback.parentElement.removeChild(invalidFeedback);
    });

    const invalidInputs = form.querySelectorAll('[class~="is-invalid"]');
    Array.from(invalidInputs).forEach((invalidInput) => {
        invalidInput.classList.remove('is-invalid');
    });

    return true;
};

const SubmitButton = (element, config) => {
    const submitButton = element.querySelector('button[type="submit"]');

    element.onkeydown = (e) => {
        const isCtrlOrdCmd = e.ctrlKey || e.metaKey;
        if (isCtrlOrdCmd && e.keyCode === 83) {
            e.preventDefault();
            submitButton.click();
        }
    };

    return {
        disable: () => submitButton.disabled = true,
        enable: () => submitButton.disabled = false
    };
};

const handleSubmit = (element, config) => {
    const formWrapper = Form(element, config);
    const form = formWrapper.form;
    const spinner = Spinner(element, config);
    const errorMessage = Error(element, config);
    const successMessage = Success(element, config);
    const submitButton = SubmitButton(element, config);

    form.addEventListener('submit', async (e) => {
        submitButton.disable();
        e.preventDefault();

        const isValid = form.checkValidity();
        if (!isValid) return undefined;
        clearPreviousErrors(form);
        successMessage.hide();

        const formData = new FormData(form);
        const action = formData.get('action');
        const method = form.method;

        if (action) {
            spinner.show();
            const response = await fetch(config.actionUrl, {
                method: method || 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: formData
            });
            const result = await response.json();

            if (result.error) {
                errorMessage.show(result.error);
                spinner.hide();
                submitButton.enable();
                if (config.onError) {
                    config.onError(result);
                }
                return undefined;
            }

            if (result.errors && Object.keys(result.errors).length) {
                const invalidFormFields = Object.entries(result.errors);

                invalidFormFields.forEach(([invalidFormField, error], idx) => {
                    const formFieldByName = form.querySelector(`[name="${invalidFormField}"]`);
                    const formFieldByDataAttr = form.querySelector(`[data-error-field="${invalidFormField}"]`);
                    const formField = formFieldByName || formFieldByDataAttr;

                    if (formField) {
                        formField.classList.add('is-invalid');

                        const errorElement = document.createElement('DIV');
                        errorElement.classList.add('invalid-feedback');
                        errorElement.innerText = error;

                        formField.parentElement.appendChild(errorElement);
                        if (idx === invalidFormFields.length - 1) {
                            formField.focus();
                        }
                    }
                });

                spinner.hide();
                submitButton.enable();
                if (config.onError) {
                    config.onError(result);
                }
                return undefined;
            }

            if (config.redirectUrl) {
                window.location.replace(config.redirectUrl);
            }

            if (config.successMessage) {
                if (config.hideFormOnSuccess) {
                    formWrapper.hide();
                }
                successMessage.show(config.successMessage);
            }

            errorMessage.hide();
            spinner.hide();
            submitButton.enable();
            if (config.onSuccess) {
                config.onSuccess(result);
            }
        }
    });
};

export const initCraftForm = (element, config) => {
    const defaults = {
        spinnerSelector: '#user-form-spinner',
        errorSelector: '#user-form-error',
        successSelector: '#user-form-success',
        redirectUrl: '',
        actionUrl: '/',
        successMessage: '',
        hideFormOnSuccess: true,
        onError: () => undefined,
        onSuccess: () => undefined
    };

    const mergedConfig = {
        ...defaults,
        ...config,
    };

    if (!element) {
        console.warn('no form element found');
        return undefined;
    }

    handleSubmit(element, mergedConfig);
};
