import {initCraftForm} from '../../_assets/scripts/craft-form';

const loginPageForm = document.querySelector('#login-page-form');
const baseUrl = window.sessionStorage.getItem('baseUrl');

if (loginPageForm) {
    initCraftForm(loginPageForm, {
        errorSelector: '#login-error-message',
        spinnerSelector: '#login-spinner',
        actionUrl: baseUrl,
        redirectUrl: `${baseUrl}login/redirect`
    });
}
