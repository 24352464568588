import {initCraftForm} from "../../_assets/scripts/craft-form";

const passwordSetForm = document.querySelector('#setpassword-form');
const baseUrl = window.sessionStorage.getItem('baseUrl');

if (passwordSetForm) {
    const setPasswordSuccess = document.querySelector('#setpassword-success-message');

    initCraftForm(passwordSetForm, {
        spinnerSelector: '#setpassword-spinner',
        errorSelector: '#setpassword-error',
        successSelector: '#setpassword-success',
        actionUrl: baseUrl,
        successMessage: setPasswordSuccess
    });
}
