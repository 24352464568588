import {initCraftForm} from "../../_assets/scripts/craft-form";

const passwordResetForm = document.querySelector('#reset-password-form');
const baseUrl = window.sessionStorage.getItem('baseUrl');

if (passwordResetForm) {
    const resetPasswordSuccess = document.querySelector('#reset-password-success-message');

    initCraftForm(passwordResetForm, {
        spinnerSelector: '#reset-password-spinner',
        errorSelector: '#reset-password-error',
        successSelector: '#reset-password-success',
        actionUrl: baseUrl,
        successMessage: resetPasswordSuccess
    });
}
